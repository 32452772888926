import { useState, useEffect,useContext } from 'react';
import { notify } from '../utils/utils';
import { useModal } from '../hooks/useModal';
import fotoDefault from '../assets/images/productoDefault.jpg';
import { AuthFetch, Fetch } from '../services/api';
import { UserContext } from '../context/provider/UserProvider';

const categoriaDefault = {
    IdCategoria: 0,
    Nombre: "",
    Descripcion: ""
}

const urlCategoria = process.env.REACT_APP_PROMETHEUS_API + '/categorias' 

export const useCategorias = () => {
    const { stateUser, signOut } = useContext(UserContext)

    const [ categorias, setCategorias] = useState([]);
    const [ categoria, setCategoria ] = useState(categoriaDefault);
    const [ isOpenModal, openModal, closeModal ] = useModal();
    const [ isOpenModalEliminar, openModalEliminar, closeModalEliminar ] = useModal();

    useEffect(() => {
        listarCategorias();
    }, []);

    const listarCategorias = async () => {
        const response = await AuthFetch({
            url: urlCategoria + '?'+ new URLSearchParams({
                base_datos    : stateUser?.baseDatos
            })
        });
        if (response.isValid) {
            setCategorias(response.content);
        } else {
            notify(response.content, 'error');
        }
    }

    const obtenerCategoria = async (codCategoria) => {
        if (codCategoria) {
            const response = await AuthFetch({
                url: urlCategoria + '/obtenerCategoria?'+ new URLSearchParams({
                    cod_categoria : codCategoria,
                    base_datos    : stateUser?.baseDatos
                })
            });
            if (response.isValid) {
                setCategoria(response.content);
            } else {
                notify(response.content, 'error');
            }
        } else {
            setCategoria(categoriaDefault);
        }
        openModal();
    }

    const categoriaEliminar = async (codCategoria) =>{
        if (codCategoria) {
            const response = await AuthFetch({
                url: urlCategoria + '/obtenerCategoria?'+ new URLSearchParams({
                  base_datos    : stateUser?.baseDatos
                })
            });

            if (response.isValid) {
                setCategoria(response.content);
            } else {
                notify(response.content, 'error');
            }
        } else {
            setCategoria(categoriaDefault);
        }
        openModalEliminar();
    }

    const eliminarCategoria = async (codCategoria)=>{
        const response = await AuthFetch({
            url: urlCategoria + '/eliminar?'+ new URLSearchParams({
                cod_categoria : codCategoria,
            }),
            method:'DELETE'
        })
        notify(response.content, response.isValid? 'success': 'error' )
        if (response.isValid) {
            listarCategorias()
        }
        closeModalEliminar()
    }

    const guardarEditarCategoria = async () => {
        const esGuardar = categoria.IdCategoria <= 0;
        const response = await AuthFetch({
            url: urlCategoria + '?' + new URLSearchParams({
                database_name  : 'BDPSmartHome',
                ...(!esGuardar && { id_categoria : categoria.IdCategoria }),
            }),
            method: esGuardar ? 'POST' : 'PUT',
            body: JSON.stringify({
                NombreCategoria:categoria.Nombre,
                DescripcionCategoria: categoria.Descripcion,
            })
        });

        notify(response.content, response.isValid ? 'success' : 'error');
        if (response.isValid) {
            setCategoria(categoriaDefault);
            await listarCategorias();
            closeModal()
        }
    }

    const editarValorCategoria = (key, value) => {
        setCategoria(categoria => {
            return {
                ...categoria,
                [key]: value
            }
        });
    }

    return { categoria, listarCategorias, categorias, editarValorCategoria, obtenerCategoria,
            isOpenModal, closeModal,eliminarCategoria, isOpenModalEliminar, 
            closeModalEliminar, categoriaEliminar,guardarEditarCategoria }

}