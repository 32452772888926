import { useState, useEffect,useContext } from 'react';
import { notify } from '../utils/utils';
import { useModal } from '../hooks/useModal';
import fotoDefault from '../assets/images/productoDefault.jpg';
import { AuthFetch, Fetch } from '../services/api';
import { UserContext } from '../context/provider/UserProvider';

const marcaDefault = {
    CodMarca: 0,
    Nombre: "",
    Descripcion: ""
}

const urlMarca = process.env.REACT_APP_PROMETHEUS_API + '/marcas' 

export const useMarcas = () => {
    const { stateUser, signOut } = useContext(UserContext)

    const [ marcas, setMarcas] = useState([]);
    const [ marca, setMarca ] = useState(marcaDefault);
    const [ isOpenModal, openModal, closeModal ] = useModal();
    const [ isOpenModalEliminar, openModalEliminar, closeModalEliminar ] = useModal();

    useEffect(() => {
        listarMarcas();
    }, []);

  const listarMarcas = async () => {
      console.log(stateUser)
        const response = await AuthFetch({
            url: urlMarca + '?'+ new URLSearchParams({
                  base_datos    : stateUser?.baseDatos
            })
        });
        if (response.isValid) {
            console.log("MARCAS -> ", response.content)
            setMarcas(response.content);
        } else {
            notify(response.content, 'error');
        }
    }

    const obtenerMarca = async (CodMarca) => {
        if (CodMarca) {
            const response = await AuthFetch({
                url: urlMarca + '/obtenerMarca?'+ new URLSearchParams({
                base_datos    : stateUser?.baseDatos
          })
            });
            console.log("RESPONSE OBTENER MARCA -> ", response)
            if (response.isValid) {
                setMarca(response.content);
            } else {
                notify(response.content, 'error');
            }
        } else {
            setMarca(marcaDefault);
        }
        openModal();
    }

    const marcaEliminar = async (CodMarca) =>{
        if (CodMarca) {
            const response = await AuthFetch({
                url: urlMarca + '/obtenerMarca?'+ new URLSearchParams({
                    cod_marca : CodMarca,
                    cod_empresa : stateUser.codEmpresa,
                })           
            });

            if (response.isValid) {
                setMarca(response.content);
            } else {
                notify(response.content, 'error');
            }
        } else {
            setMarca(marcaDefault);
        }
        openModalEliminar();
    }

    const eliminarMarca = async (CodMarca)=>{
        const response = await AuthFetch({
            url: urlMarca + '/eliminar?'+ new URLSearchParams({
                cod_marca : CodMarca,
                cod_empresa : stateUser.codEmpresa,
            }),
            method:'DELETE'
        })
        notify(response.content, response.isValid? 'success': 'error' )
        if (response.isValid) {
            listarMarcas()
        }
        closeModalEliminar()
    }

    const guardarEditarMarca = async () => {
        const esGuardar = marca.CodMarca <= 0;
        const response = await AuthFetch({
            url: urlMarca,
            method: esGuardar ? 'POST' : 'PUT',
            body: JSON.stringify({
                ...(!esGuardar && { CodMarca: marca.CodMarca }),
                // CodCategoria: categoria.CodCategoria,
                Nombre:marca.Nombre,
                Descripcion: marca.Descripcion,
                codEmpresa : stateUser.codEmpresa
            })
        });

        notify(response.content, response.isValid ? 'success' : 'error');
        if (response.isValid) {
            setMarca(marcaDefault);
            await listarMarcas();
            closeModal()
        }
    }

    const editarValorMarca = (key, value) => {
        setMarca(marca => {
            return {
                ...marca,
                [key]: value
            }
        });
    }

    return { marca, listarMarcas, marcas, editarValorMarca, obtenerMarca,
            isOpenModal, closeModal,eliminarMarca, isOpenModalEliminar, 
            closeModalEliminar, marcaEliminar,guardarEditarMarca }

}