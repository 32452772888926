import React, { useEffect, useState, useRef } from 'react';
import { BotonNuevo } from '../../components/buttons/BotonNuevo';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros';
import { SearchTable } from '../../components/utils/SearchTable';
// import { Modal } from '../../components/modal/Modal';
// import { useProductos } from '../../../hooks/useProductos';
import { useCategorias } from '../../../hooks/useCategorias';
import { Side } from '../../components/modal/Side';
import InputText from '../../components/InputText';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
// import { fontSize } from '@mui/system';

export const Categorias = () => {

    const { categoria, listarCategorias, categorias, editarValorCategoria, obtenerCategoria, isOpenModal, closeModal,eliminarCategoria, isOpenModalEliminar, closeModalEliminar, categoriaEliminar,guardarEditarCategoria} = useCategorias()

    const idTablaCategorias = "tablacategorias"

    return (
        <>

            <ContenedorParametros>

            <BotonNuevo onClick={() => obtenerCategoria()} icono="fa fa-plus-circle">Nuevo</BotonNuevo>
                <SearchTable tablaId={idTablaCategorias}></SearchTable>
            </ContenedorParametros>


            <div className="containerScroll relative contenedor-tabla ">
                <table id={idTablaCategorias} className="tableResponsiveProductos" >
                    <thead className=''>
                        <tr >
                            {/* <th>N</th> */}
                            <th>Cod</th>
                            <th>Nombre</th>
                            <th>Descripción</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {categorias.map((categoria,i ) => (
                            <tr key={categoria.IdCategoria} className="text-center center center-items">
                                {/* <td data-th="Nombre">{++i}</td> */}
                                <td data-th="Nombre">{categoria.IdCategoria}</td>
                                <td data-th="Nombre">{categoria.NombreCategoria}</td>
                                <td data-th="Descripcion">{categoria.DescripcionCategoria}</td>
                                <td data-th="Opciones">
                                    <button onClick={() => obtenerCategoria(categoria.IdCategoria)} className="hover:text-green-300 text-green-500 text-xl px-[5px] py-1 rounded">
                                        <i className="fas fa-edit"></i>
                                    </button>
                                    <button  onClick={()=> categoriaEliminar(categoria.IdCategoria)} className="hover:text-red-300 text-red-500 text-xl px-[5px] py-1 rounded ml-3">
                                        <i className="fas fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>



            <Side 
                isOpen={isOpenModal} closeModal={closeModal} 
                action={() => guardarEditarCategoria()}
                title="Registrar Categoria"
            >
            <div className='h-[300px]  lg:h-[500px] '>
                <div className="flex  items-center  justify-center">
                </div>

                
                    <InputText classBox='' id='nombreCategoria' label="Nombre de Categoria" type="text" defaultValue={categoria.Nombre} onChange={(e) => editarValorCategoria("Nombre", e.target.value)}/> 
                    <InputText classBox='' multilinea={4} id='descripcionCategoria' label="Descripción" type="text" defaultValue={categoria.Descripcion} onChange={(e) => editarValorCategoria("Descripcion", e.target.value)}/> 
                

                {/* <div className="wrapper">
                    <form className='form'> 
                        <div className="group mt-8">
                            <input className='inputRegistro' type="text" required="required" defaultValue={categoria.Nombre} onChange={(e) => editarValorCategoria("Nombre", e.target.value)} />
                                <span className="highlight"></span><span className="bar"></span>
                            <label className='labelRegistro' >Nombre</label>
                        </div>
                        

                        <div className="group mt-8">
                            <textarea className='textareaRegistro' type="textarea" rows="5" required="required" defaultValue={categoria.Descripcion} onChange={(e) => editarValorCategoria("Descripcion", e.target.value)}></textarea>
                            <span className="highlight"></span><span className="bar"></span>
                            <label className='labelRegistro' >Descripción</label>
                        </div>
                    </form>
                </div> */}
            </div>
            </Side>

            <Side 
                isOpen={isOpenModalEliminar} closeModal={closeModalEliminar} action={() => eliminarCategoria(categoria.CodCategoria)}
                title= "Eliminar Categoría"
                spinner={{}}
                textButtons={{ confirm: 'Si', denied: 'No' }}
                >
                
                <div className=' text-center '>

                <ErrorOutlineIcon className='mb-4 center text-yellow-500 ' style={{fontSize:"100px"}} />


                    <div className="flex gap-8 items-center justify-center">
                        <div className=" text-center">
                        <label className="text-center text-xl text-gray-800">¿Está seguro de eliminar la Categoría? </label>
                            <hr></hr>
                            <div className='text-center mt-5  content-center items-center'>
                                <div className=''>
                                    <label className="text-center  text-gray-800 font-bold text-2xl ">-- {categoria.Nombre} --</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Side>   
        </>
    )

}