import React, { useEffect, createContext, useReducer } from "react";
import { UserReducers } from '../reducers/UserReducers'
import { UserTypes } from "../types/UserTypes"

export const UserContext = createContext()

export function UserProvider(props) {
  const decifrado = atob(localStorage.getItem('pm-session') || '')
  const datosSession = decifrado == '' ? {} : JSON.parse(decifrado);


  const initialUserState = {
    codUsuario: datosSession.codUsuario || 0,
    nomUsuario: datosSession.nomUsuario || '',
    nombre: datosSession.nombre || '',
    apellidoPaterno:  datosSession.apellidoPaterno || '',
    apellidoMaterno:  datosSession.apellidoMaterno || '',
    fechaNacimiento:  datosSession.fechaNacimiento || '',
    direccion:  datosSession.direccion || '',
    celular:  datosSession.celular || '',
    email:  datosSession.email || '',
    codEmpresa:  datosSession.codEmpresa || '',
    foto:  datosSession.foto || '',
    link: datosSession.link || '',
    token: datosSession.token || '',
    tokenExpire: datosSession.tokenExpire || 0,
    menus: datosSession.menus || [],
    permisos: datosSession.permisos || [],
    baseDatos: datosSession?.accesos?.length > 1 ? datosSession?.accesos[0]?.baseDatos : ''
  }

  const [ state, dispatch ] = useReducer(UserReducers, initialUserState)

  const signIn = (objSession) => {
    var cifrado = btoa(JSON.stringify(objSession));
    localStorage.setItem('pm-session', cifrado);
    dispatch({type: UserTypes.SIGN_IN, payload: objSession})
  }

  const signOut = () => {
    localStorage.clear();
    dispatch({type: UserTypes.SIGN_OUT})
  }

  return (
        <UserContext.Provider value={{stateUser: state, signIn, signOut}}>
            {props.children}
        </UserContext.Provider>
    )
}